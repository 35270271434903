<template>
  <div :class="['subMenu', { subActive: subActive }]" @click="isOpen = !isOpen">
    <div class="subItem rootMenuItem">
      {{ item.groupText }}
      <img
        :class="{ isOpen: isOpen }"
        :src="require(`@/assets/imgs/comm/arrow${theme == 'dark' ? '-dark' :''}.svg`)"
      />
    </div>
    <div :class="['subItemWrap', { closeSubItem: !isOpen }]">
      <div
        v-for="groupItem in item.groupArr"
        :key="groupItem.groupValue"
        :class="['menuItem', { active: active == groupItem.groupValue }]"
        @click.stop="menusHandle(groupItem.groupValue)"
      >
        {{ groupItem.groupText }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LeftMenus",
  props: ["item", "menusHandle", "active"],
  data() {
    return {
      isOpen: false,
    };
  },
  created(){
      this.isOpen = !!this.item.groupArr.find((gItem) => gItem.groupValue == this.active);
  },
  computed: {
    subActive() {
      return this.item.groupArr.find((gItem) => gItem.groupValue == this.active);
    },
    theme() {
      return this.common.theme;
    },
    ...mapState(["common"]),
  },
};
</script>

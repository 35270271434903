<template>
<div>
  <div class="leftMenus hid-scrollbar">
    <div v-for="(item, key) in menus" :key="key">
      <template v-if="item.groupArr">
        <SubItem :item="item" :menusHandle="menusHandle" :active="active" />
      </template>
      <div
        v-else
        :class="{
          menuItem: true,
          rootMenuItem: true,
          active: active == item.groupValue,
          rootActive: active == item.groupValue,
        }"
        @click="menusHandle(item.groupValue)"
      >
        {{ item.groupText }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import SubItem from "./subItem.vue";
export default {
  components: {
    SubItem,
  },
  name: "LeftMenus",
  props: ["menus", "menusHandle", "active"],
};
</script>

<style lang="scss">
.leftMenus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 325px;
  border: 1px solid $border-color;
  border-radius: 10px;
  overflow: auto;
  &>div{  
    width: 100%;
  }
  .subMenu{
    .menuItem{
      font-weight: 400;
    }
  }
  .menuItem ,.subItem{
    line-height: 40px;
    padding-left: 30px;
    padding-right: 15px;
    border-bottom: 1px solid $border-color;
    position: relative;
    font-weight: 600;
    font-size: 13px;
    color: $menu-text;
    cursor: pointer;
  }
  // .subItemWrap .menuItem{
  //   background: rgba(0, 0, 0, 0.40);
  // }
  .menuItem{
    // &:last-child {
    //   border-bottom: 0;
    // }
    // &:hover {
    //   background: $scmenu-bg-color;
    // }
  }
  .rootMenuItem{
    padding-left: 15px;
  }
  .subActive .subItem, .rootActive{
    &::after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: #0238E0;
    }
  }
  .active{
    color: #0238E0;
  }
  .active {
    // background: $scmenu-bg-color;
    color: #0238E0;
  }
  .subItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      transform: rotate(180deg);
    }
    .isOpen {
      transform: rotate(0);
    }
  }
  .subItemWrap {
    transition: 0.2s;
    display: block;
  }
  .closeSubItem {
    transition: 0.2s;
    display: none;
  }
}
.dark{
  .leftMenus{
    background: #151618;
  }
  .leftMenus .subMenu .menuItem{
    background: #000;
  }
}
</style>

import axios from 'axios';
// import db from '@/utils/localStorage/index'
import router from '@/router/index';
// import store from '@/store/index';
import { Loading, Message } from 'element-ui';
import i18n from '@/lang'

let needLoadingRequestCount = 0;
function showFullScreenLoading(text) {
  if (needLoadingRequestCount === 0) {
    startLoading(text)
  }
  needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
let loading = null;

function errMsgHandle(resp) { // global server error message handle here
  // console.log(resp.code);
  if (resp.code in i18n.messages["en"].errorcode['SERVER_ERRORS']) {
    Message.error(i18n.tc("errorcode.SERVER_ERRORS." + resp.code));
  } else {
    Message.error(resp.msg || resp.message)
  }
}

function startLoading(text) {
  loading = Loading.service({
    fullscreen: true,
    lock: true,
    text: text || '',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    customClass: 'globalServiceLoading'
  });


}

function endLoading() {
  loading.close()
}
//const hash = 'sUSH5cWhx4gsSRBn';

export const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/' + process.env.VUE_APP_API_PREFIX,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
    // Authorization: `${db.get('AUTH', '')}`,
  },
  method: 'post',
  // timeout: 10000
});

service.interceptors.request.use(function (config) {
  if (config.loading) {
    showFullScreenLoading(config.message)
  }
  // let Authorization = db.get('AUTH', '');
  // if (Authorization && !config.removeAuth) {
  //   config.headers['Authorization'] = Authorization;
  // }
  if (config.headers['Content-Type'].includes("application/x-www-form-urlencoded")) {
    config.transformRequest = [function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }]
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});


service.interceptors.response.use(function (response) {

  //console.log(response.config);

  if (response.config.loading) {
    tryHideFullScreenLoading()
  }
  // if (response.status == '451') {
  //   if (router.currentRoute.name === 'home') {
  //     return response;
  //   }

  //   return store.dispatch("user/logout");
  // }
  const res = response.data;

  if (res.code < 0) {
    if (!response.config.errorNoDialog) {
      errMsgHandle(res);
    }
    return res
  } else {
    return res
  }
  // return response;
}, error => {

  tryHideFullScreenLoading()
  //console.log(error) // for debug
  if (error.response.status == '451' || error.response.status == '452') {
    if (router.currentRoute.name === 'home') {
      return error.response;
    }

    // return store.dispatch("user/logout");
  }
  let _message = '';
  if (error.response && error.response.status >= 500) {
    _message = 'system error';
  } else if (!error.response) {
    _message = 'system error'
  } else {
    _message = error.message;
  }
  Message.error(_message)
  return Promise.reject(error)
});

export default service